//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import { validateContact } from '~/services/utilityFunctions';

export default {
    name: 'EmailInput',
    props: {
        tab: {
            type: String,
            default: 'login'
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        ctaInSameLine: {
            type: Boolean,
            default: false
        },
        isAcquisitionAuth: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            email: '',
            errorMessage: '',
            showLoginMessage: false
        };
    },
    computed: {
        ...mapState('auth', {
            candidateEmail: 'candidateEmail',
            otpLoading: 'otpLoading',
            loginMethod: 'loginMethod'
        })
    },
    mounted() {
        this.email = this.candidateEmail;
    },
    methods: {
        async handleSubmit() {
            if (this.otpLoading || this.errorMessage) {
                return;
            }

            // Check whether the email is valid (received from backend)
            const isValid = await this.validateEmail();

            if (isValid) {
                this.$store.commit('auth/setOtpLoading', true);
                this.$store.commit('auth/setCandidateEmail', this.email);
                this.$emit('sendOtp', {
                    loginMethod: this.loginMethod,
                    email: this.email
                });
            }
        },

        async validateEmail() {
            const { isValid, message } = await validateContact({
                contactType: 'email',
                email: this.email,
                api: this.$api
            });

            if (!isValid) {
                this.errorMessage = message;
                return false;
            }
            return true;
        },

        resetDefaults() {
            this.errorMessage = '';
        }
    }
};
